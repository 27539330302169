.App {
    text-align: center;
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

p {
    color: green;
}

.contact-icons-ctr {
    margin-top: 15px;
    display: flex;
    column-gap: 20px;
}

a,
a:visited {
    color: inherit;
}

.Typewriter__wrapper {
    font-size: larger;
    font-family: "Source Sans Pro", sans-serif;
}
